/* globals List */
import view from 'bundle-text:../dom/messages.html'; // eslint-disable-line
import nav from 'bundle-text:../dom/_nav.html'; // eslint-disable-line
import app from './app';

export default () => {
  /*
  const footer = document.querySelector('#app-footer');
  footer.classList.remove('position-absolute');
  */

  const container = document.querySelector('#app-content');
  container.classList.remove('align-items-center');
  container.classList.remove('d-flex');

  const content = document.createElement('div');
  /*
  content.classList.add('align-items-center');
  content.classList.add('d-flex');
  content.classList.add('justify-content-center');
  content.classList.add('min-h-100');
  content.classList.add('w-100');
  */
  content.innerHTML = view;

  content.querySelector('#app-nav').innerHTML = nav;
  content.querySelector('.nav-link').classList.remove('active');
  content.querySelector(`[href="${window.location.hash}"]`).classList.add('active');

  document.title = `Messages | ${app.conf.title}`;

  /*
  const messagesBody = content.querySelector('#app-messages-table');
  */
  const messageList = new List(content.querySelector('#app-messages'), { valueNames: ['subject', 'expert', 'date', 'status'] }); // eslint-disable-line

  return content;
};
