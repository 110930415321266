/* globals bootstrap */
import view from 'bundle-text:../dom/signIn.html'; // eslint-disable-line
import app from './app';

export default () => {
  const content = document.createElement('div');
  content.classList.add('align-items-center');
  content.classList.add('d-flex');
  content.classList.add('justify-content-center');
  content.classList.add('min-h-100');
  content.classList.add('w-100');
  content.innerHTML = view;

  document.title = `Sign in | ${app.conf.title}`;

  const alertBody = document.getElementById('app-modal-alert-body');
  const alertModal = new bootstrap.Modal('#app-modal-alert');
  const form = content.querySelector('#app-form-signIn');

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    const formData = new FormData(form);

    fetch(form.action, {
      body: JSON.stringify(Object.fromEntries(formData.entries())),
      method: form.method,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        if (json._token) {
          app.lib.token.set(json._token);
          if (app.run.tokenObject.user && app.run.tokenObject.user.email && app.run.tokenObject.user.email !== '') {
            window.location.hash = '/dashboard';
          } else {
            throw new Error('Undefined error.');
          }
        } else {
          throw new Error('Undefined error.');
        }
      })
      .catch((error) => {
        alertBody.innerHTML = error;
        alertModal.show();
      });
  });

  return content;
};
