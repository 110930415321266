import view from 'bundle-text:../dom/verifySignIn.html'; // eslint-disable-line
import app from './app';

export default () => {
  const content = document.createElement('div');
  content.classList.add('align-items-center');
  content.classList.add('d-flex');
  content.classList.add('justify-content-center');
  content.classList.add('min-h-100');
  content.classList.add('w-100');
  content.innerHTML = view;

  document.title = `Verify sign in | ${app.conf.title}`;

  let timerSeconds = 5 * 60;

  let m = 0;
  let s = 0;

  const timerElement = content.querySelector('#app-verifySignIn-timer');
  const timer = setInterval(() => {
    timerSeconds -= 1;

    if (timerSeconds <= 0) {
      clearInterval(timer);
    }

    s = timerSeconds;
    m = 0;
    while (s >= 60) {
      m += 1;
      s -= 60;
    }

    if (s < 10) {
      s = `0${s}`;
    }

    timerElement.innerHTML = `${m}:${s}`;
  }, 1000);

  const resendIcon = content.querySelector('#app-verifySignIn-resend > i');
  const resendLink = content.querySelector('#app-verifySignIn-resend');
  resendLink.addEventListener('click', (e) => {
    e.preventDefault();

    resendIcon.classList.remove('d-none');
    resendLink.setAttribute('disabled', true);

    timerSeconds = 5 * 60;

    setTimeout(() => {
      resendIcon.classList.add('d-none');
      resendLink.removeAttribute('disabled');
    }, 2000);
  });

  return content;
};
