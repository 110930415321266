import view from 'bundle-text:../dom/404.html'; // eslint-disable-line
import app from './app';

export default () => {
  const content = document.createElement('div');
  content.classList.add('align-items-center');
  content.classList.add('d-flex');
  content.classList.add('justify-content-center');
  content.classList.add('min-h-100');
  content.classList.add('w-100');
  content.innerHTML = view;

  document.title = `Page not found! | ${app.conf.title}`;

  return content;
};
