import app from './app';

const token = {
  check: (jwt) => {
    if (jwt.exp < Math.floor(Date.now() / 1000)) {
      return false;
    }

    return true;
  },

  parse: (jwt) => {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  },

  run: () => {
    const storedToken = localStorage.getItem('token');

    if (!storedToken) {
      window.location.hash = '';
    } else if (!token.check(token.parse(storedToken))) {
      window.location.hash = '';
    } else {
      token.set(storedToken);
    }
  },

  set: (jwt) => {
    app.run.token = jwt;
    app.run.tokenObject = token.parse(jwt);
    localStorage.setItem('token', jwt);
    token.setHeader(jwt);

    // app.proxy.state = 'ready';
  },

  setHeader: (jwt) => {
    app.run.headers = {
      'X-Auth-Token': jwt,
    };
  },
};

export default token;
