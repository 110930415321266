import view from 'bundle-text:../dom/profile.html'; // eslint-disable-line
import nav from 'bundle-text:../dom/_nav.html'; // eslint-disable-line
import app from './app';

export default () => {
  /*
  const footer = document.querySelector('#app-footer');
  footer.classList.remove('position-absolute');
  */

  const container = document.querySelector('#app-content');
  container.classList.remove('align-items-center');
  container.classList.remove('d-flex');
  // container.classList.remove('min-h-100');

  const content = document.createElement('div');
  /*
  content.classList.add('align-items-center');
  content.classList.add('d-flex');
  content.classList.add('justify-content-center');
  content.classList.add('min-h-100');
  content.classList.add('w-100');
  */
  content.innerHTML = view;

  content.querySelector('#app-nav').innerHTML = nav;
  content.querySelector('.nav-link').classList.remove('active');
  content.querySelector(`[href="${window.location.hash}"]`).classList.add('active');

  document.title = `Profile | ${app.conf.title}`;

  console.log(app.run.tokenObject.user);

  content.querySelector('#app-form-profile-biologicalSex').value = app.run.tokenObject.user.biologicalSex;
  content.querySelector('#app-form-profile-dateOfBirth').value = app.run.tokenObject.user.dateOfBirth;
  content.querySelector('#app-form-profile-nameFirst').value = app.run.tokenObject.user.nameFirst;
  content.querySelector('#app-form-profile-nameLast').value = app.run.tokenObject.user.nameLast;

  return content;
};
