const app = {
  conf: {
    title: 'My Digital Twin | Near Real',
  },

  fn: {
    run: null,
  },

  lib: {
    router: null,
    token: null,
  },

  run: {
    token: null,
    tokenObject: null,
  },
};

app.fn.run = () => {
  document.title = app.conf.title;

  app.lib.token.run();

  app.lib.router(window.location.hash);
  window.addEventListener('hashchange', () => {
    app.lib.router(window.location.hash);
  });

  document.getElementById('app-loader').classList.add('d-none');
};

export default app;
