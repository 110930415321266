import view from 'bundle-text:../dom/examination.html'; // eslint-disable-line
import nav from 'bundle-text:../dom/_nav.html'; // eslint-disable-line
import app from './app';

export default () => {
  const container = document.querySelector('#app-content');
  container.classList.remove('align-items-center');
  container.classList.remove('d-flex');

  const content = document.createElement('div');
  /*
  content.classList.add('align-items-center');
  content.classList.add('d-flex');
  content.classList.add('justify-content-center');
  content.classList.add('min-h-100');
  content.classList.add('w-100');
  */
  content.innerHTML = view;

  content.querySelector('#app-nav').innerHTML = nav;
  content.querySelector('.nav-link').classList.remove('active');
  content.querySelector(`[href="${window.location.hash}"]`).classList.add('active');

  document.title = `Examination | ${app.conf.title}`;

  const examination = content.querySelector('#app-view-examination-examination');
  const guide = content.querySelector('#app-view-examination-guide');
  const start = content.querySelector('#app-view-examination-start');
  const stop = content.querySelector('#app-view-examination-stop');
  const timer = content.querySelector('#app-view-examination-timer');
  const video = content.querySelector('#app-view-examination-video');

  let formattedM;
  let formattedS;
  let formattedTime = null;
  let interval = null;
  let media = null;
  let m = 0;
  let s = 0;

  start.addEventListener('click', (e) => {
    e.preventDefault();

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        console.log(stream);

        media = stream;

        video.srcObject = stream;
        video.onloadedmetadata = () => {
          video.play();
        };

        timer.classList.add('text-danger');
        timer.innerHTML = '00:00';

        s = 0;
        interval = setInterval(() => {
          s += 1;

          formattedS = s;
          formattedM = 0;

          if (formattedS >= 15 && formattedS < 30) {
            timer.classList.remove('text-danger');
            timer.classList.add('text-warning');
          } else {
            timer.classList.remove('text-warning');
            timer.classList.add('text-success');
          }

          while (s >= 60) {
            formattedM += 1;
            formattedS -= 60;
          }

          if (formattedM < 10) {
            formattedM = `0${m}`;
          }
          if (formattedS < 10) {
            formattedS = `0${s}`;
          }

          formattedTime = `${formattedM}:${formattedS}`;
          timer.innerHTML = formattedTime;
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });

    guide.classList.add('d-none');
    examination.classList.remove('d-none');
  });

  stop.addEventListener('click', (e) => {
    e.preventDefault();

    // video.stop();
    video.srcObject = null;
    media.getTracks().forEach((track) => {
      track.stop();
    });

    timer.innerHTML = '';
    clearInterval(interval);

    examination.classList.add('d-none');
    guide.classList.remove('d-none');
  });

  // app-view-examination-guide
  // app-view-examination-start
  // app-view-examination-examination
  // app-view-examination-timer
  // app-view-examination-video
  // app-view-examination-stop

  /*
  */

  return content;
};
