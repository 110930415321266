import view from './view';

const router = async (route) => {
  const content = document.getElementById('app-content');
  content.innerHTML = '';

  switch (route) {
    case '': {
      content.appendChild(view.landing());
      break;
    }

    case '#/appointments': {
      content.appendChild(view.appointments());
      break;
    }

    case '#/dashboard': {
      content.appendChild(view.dashboard());
      break;
    }

    case '#/event-log': {
      content.appendChild(view.eventLog());
      break;
    }

    case '#/examination': {
      content.appendChild(view.examination());
      break;
    }

    case '#/health-data': {
      content.appendChild(view.healthData());
      break;
    }

    case '#/messages': {
      content.appendChild(view.messages());
      break;
    }

    case '#/profile': {
      content.appendChild(view.profile());
      break;
    }

    case '#/questionnaire': {
      content.appendChild(view.questionnaire());
      break;
    }

    case '#/sign-in': {
      content.appendChild(view.signIn());
      break;
    }

    case '#/sign-out': {
      content.appendChild(view.signOut());
      break;
    }

    case '#/sign-up': {
      content.appendChild(view.signUp());
      break;
    }

    case '#/verify-sign-in': {
      content.appendChild(view.verifySignIn());
      break;
    }

    default: {
      content.appendChild(view.notFound());
    }
  }
};

export default router;
