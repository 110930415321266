import appointments from './view.appointments';
import dashboard from './view.dashboard';
import eventLog from './view.eventLog';
import examination from './view.examination';
import healthData from './view.healthData';
import landing from './view.landing';
import messages from './view.messages';
import notFound from './view.404';
import profile from './view.profile';
import questionnaire from './view.questionnaire';
import signIn from './view.signIn';
import signOut from './view.signOut';
import signUp from './view.signUp';
import verifySignIn from './view.verifySignIn';

const view = {
  appointments,
  dashboard,
  eventLog,
  examination,
  healthData,
  landing,
  messages,
  notFound,
  profile,
  questionnaire,
  signIn,
  signOut,
  signUp,
  verifySignIn,
};

export default view;
