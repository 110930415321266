import view from 'bundle-text:../dom/signUp.html'; // eslint-disable-line
import app from './app';

export default () => {
  const content = document.createElement('div');
  content.classList.add('align-items-center');
  content.classList.add('d-flex');
  content.classList.add('justify-content-center');
  content.classList.add('min-h-100');
  content.classList.add('w-100');
  content.innerHTML = view;

  document.title = `Sign up | ${app.conf.title}`;

  const formAuthenticate = content.querySelector('#app-form-signUp-authenticate');
  const formProfile = content.querySelector('#app-form-signUp-profile');
  const submitAuthenticate = content.querySelector('#app-form-signUp-authenticate-submit');
  const submitProfile = content.querySelector('#app-form-signUp-profile-submit');
  const submitVerify = content.querySelector('#app-form-signUp-verify-submit');
  const triggerProfile = content.querySelector('#app-tab-signUp-profile-tab');
  const triggerVerify = content.querySelector('#app-tab-signUp-verify-tab');

  submitAuthenticate.addEventListener('click', () => {
    formAuthenticate.classList.add('was-validated');

    if (formAuthenticate.checkValidity() === false) {
      formAuthenticate.reportValidity();
    } else {
      triggerProfile.removeAttribute('disabled');
      triggerProfile.click();
    }
  });

  submitProfile.addEventListener('click', () => {
    formProfile.classList.add('was-validated');

    if (formProfile.checkValidity() === false) {
      formProfile.reportValidity();
    } else {
      triggerVerify.removeAttribute('disabled');
      triggerVerify.click();
    }
  });

  submitVerify.addEventListener('click', () => {
    const formAuthenticateData = new FormData(formAuthenticate);
    const formProfileData = new FormData(formProfile);

    const formData = {
      ...Object.fromEntries(formAuthenticateData.entries()),
      ...Object.fromEntries(formProfileData.entries()),
    };

    // console.log(formData);

    fetch('/api/sign-up', {
      body: JSON.stringify(formData),
      method: 'post',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        if (json._token) {
          app.lib.token.set(json._token);
          if (app.run.tokenObject.user && app.run.tokenObject.user.email && app.run.tokenObject.user.email !== '') {
            window.location.hash = '/dashboard';
          } else {
            throw new Error('Undefined error.');
          }
        } else {
          throw new Error('Undefined error.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const resendIcon = content.querySelector('#app-signUp-verify-resend > i');
  const resendLink = content.querySelector('#app-signUp-verify-resend');
  resendLink.addEventListener('click', (e) => {
    e.preventDefault();

    resendIcon.classList.remove('d-none');
    resendLink.setAttribute('disabled', true);

    setTimeout(() => {
      resendIcon.classList.add('d-none');
      resendLink.removeAttribute('disabled');
    }, 2000);
  });

  return content;
};
